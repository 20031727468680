import { render, staticRenderFns } from "./Documenti.vue?vue&type=template&id=a76dc6ae&"
import script from "./Documenti.vue?vue&type=script&lang=js&"
export * from "./Documenti.vue?vue&type=script&lang=js&"
import style0 from "./Documenti.vue?vue&type=style&index=0&id=a76dc6ae&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QUploader from 'quasar/src/components/uploader/QUploader.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QUploaderAddTrigger from 'quasar/src/components/uploader/QUploaderAddTrigger.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupEdit from 'quasar/src/components/popup-edit/QPopupEdit.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QBtn,QSeparator,QUploader,QTooltip,QSpinner,QUploaderAddTrigger,QTable,QTr,QTd,QIcon,QPopupEdit,QInput,QBtnDropdown,QList,QItem,QItemSection,QItemLabel,QDialog,QSpace,QCardActions});qInstall(component, 'directives', {ClosePopup});
