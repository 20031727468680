<template>
  <div class="q-mx-lg">
    <div class="row q-mt-xl q-mb-lg">
      <div class="col-md-12">
        <h5 class="q-ma-none text-bold">Dashboard</h5>
      </div>
      <!-- form id="form1" method="GET" action="/login2">
        <input
          type="hidden"
          value="1622104760555-4WnHWK55AaoXZ1ZsGex"
          id="plain"
          name="plain" />
        <input
          type="hidden"
          value="7u2gssLT1Hogfusmst+pqgIuXh0IDAemyTWMwoLQMxGBiRxfQWFiCNJgHdSTBpwj"
          id="enc"
          name="enc" />
        <input type="hidden" value="admin@admin.it" id="userid" name="userid" />
        <button class="login" type="submit">Simula login</button>
      </form -->
    </div>
    <div class="row" v-if="!checkAuth">
      <div class="col-md-12">
        <q-banner inline-actions rounded class="bg-amber-1 text-grey-8" v-if="getUserByDb.id">
          Non sei abilitato ad accedere alla piattaforma. L'amministratore provvederà a darti i permessi.
        </q-banner>
        <q-banner inline-actions rounded class="bg-amber-1 text-grey-8" v-if="!getUserByDb.id">
          Sessione scaduta. Clicca <a :href="loginURL">qui</a> per autenticarti nuovamente.
        </q-banner>
      </div>
    </div>
    <div v-if="checkAuth">
      <div class="row q-mt-lg q-col-gutter-md">
        <!--div class="col-md-4">
          <kpi-num-casette></kpi-num-casette>
        </div>
        <div class="col-md-8">
          <kpi-acqua-erogata></kpi-acqua-erogata>
        </div-->
        <div class="col-md-12">
          <kpi-alert-casette v-if="isAuthorized"></kpi-alert-casette>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import KpiNumCasette from '@/components/KpiNumCasette'
import KpiAlertCasette from '@/components/KpiAlertCasette'
// import KpiAcquaErogata from '@/components/KpiAcquaErogata'

export default {
  name: 'Dashboard',
  components: { KpiAlertCasette },
  /* components: {
    KpiNumCasette,
    KpiAlertCasette,
    KpiAcquaErogata
  }, */
  data: () => ({ loginURL: null }),
  mounted() {
    this.getLoginURL()
  },
  methods: {
    getLoginURL() {
      this.$http.get('/getLoginURL', {})
        .then((result) => {
          this.loginURL = result.data.url
        })
    }
  },
  computed: {
    getUserByDb() {
      return this.$store.getters.getUserByDb
    },
    isAuthorized() {
      return (
        this.getUserByDb && this.getUserByDb.id && (
        this.$_.includes(this.getUserByDb.ruoli, 'admin') ||
        this.$_.includes(this.getUserByDb.ruoli, 'casette')
      ))
    },
    checkAuth() {
      return (this.getUserByDb && this.getUserByDb.id && this.getUserByDb.ruoli && this.getUserByDb.ruoli.length)
    }
  }
}
</script>
