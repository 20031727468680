<template>
  <q-card class="q-mt-md">
    <q-card-section>
      <span class="text-h6">Case dell'acqua da controllare</span>
    </q-card-section>
    <q-card-section>
      <div>
        <q-table
          table-header-class="bg-blue-grey-1 text-grey-8"
          class="casette-table"
          :data="dati"
          :columns="columns"
          :grid="$q.screen.xs">
          <template v-slot:body-cell-stato="props">
            <q-td :props="props">
              <div v-if="props.row.stato === 'attiva'">
                <q-icon
                  name="circle"
                  class="text-green"
                  style="font-size: 16px; line-height: 0.8" />
                Attiva
              </div>

              <div v-if="props.row.stato === 'da attivare'">
                <q-icon
                  name="circle"
                  class="text-grey"
                  style="font-size: 16px; line-height: 0.8" />
                Da attivare
              </div>

              <div v-if="props.row.stato === 'in manutenzione'">
                <q-icon
                  name="circle"
                  class="text-orange"
                  style="font-size: 16px; line-height: 0.8" />
                In manutenzione
              </div>

              <div v-if="props.row.stato === 'chiusa'">
                <q-icon
                  name="circle"
                  class="text-red"
                  style="font-size: 16px; line-height: 0.8" />
                Chiusa
              </div>
            </q-td>
          </template>
          <template v-slot:body-cell-alert="props">
            <q-td :props="props">
              <tipo-allarme :tipo="props.row.tipo" />
            </q-td>
          </template>
          <template v-slot:body-cell-actions="props">
            <q-td :props="props">
              <q-btn
                size="11px"
                no-caps
                color="primary"
                label="Dettagli"
                @click="openDetails(props.row.casetta_id)" />
            </q-td>
          </template>
        </q-table>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import { date } from "quasar";
import TipoAllarme from './TipoAllarme.vue'

export default {
  name: 'KpiAlertCasette',
  components: { 'tipo-allarme': TipoAllarme },
  data: () => ({
    dati: [],
    pagination: {
      sortBy: 'updatedAt',
      descending: false,
      page: 1,
      rowsPerPage: 5
    },
    columns: [
      {
        name: 'indirizzo',
        align: 'left',
        label: 'INDIRIZZO',
        field: 'indirizzo',
        sortable: true
      },
      {
        name: 'indicazioni_aggiuntive',
        align: 'left',
        label: 'INDICAZIONI AGGIUNTIVE',
        field: 'indicazioni_aggiuntive',
        sortable: true
      },
      {
        name: 'comune',
        align: 'left',
        label: 'COMUNE',
        field: 'comune',
        sortable: true
      },
      {
        name: 'stato',
        align: 'left',
        label: 'STATO',
        field: 'stato',
        sortable: true
      },
      {
        name: 'alert',
        align: 'left',
        label: 'ALERT',
        field: 'alert',
        sortable: true
      },
      {
        name: 'data',
        align: 'left',
        label: 'DATA',
        field: 'data',
        sortable: false
      },
      {
        name: 'actions',
        align: 'right',
        label: 'AZIONI'
      },
    ],
    filter: ''
  }),

  mounted() {
    this.getData()
  },

  methods: {
    openDetails(id) {
      this.$router.push({
        name: 'Casetta',
        params: { id: id }
      })
    },

    getData() {
      this.$http.get('/api/allarmi/dashboard').then((results) => {
        this.dati = results.data
        this.dati.forEach(d => d.data = date.formatDate(d.time_stamp, 'DD/MM/YYYY - HH:mm'))
      })
    }
  }
}
</script>

<style></style>
